
import { Component, Signal, computed, inject, input } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { BookmarksStore } from '../../store/bookmarks/bookmarks.store';
import { AppConstants } from '../../config/constants.config';
import { IBookmark } from '../../models/bookmark.model';

type Pin = IBookmark & {
	disabled: boolean
	marked: boolean
}

@Component({
    selector: 'bookmarker',
    templateUrl: './bookmarker.component.html',
    host: {
        '[class.marked]': 'item()?.marked',
    },
    imports: [TranslateModule, NgbTooltipModule]
})
export class BookmarkerComponent {
	max = AppConstants.BOOKMARKS_LIMIT
	store = inject(BookmarksStore);

	bookmark = input.required<IBookmark>();
	item: Signal<Pin|null> = computed(() => {
		const bk = this.bookmark();
		if (!bk) return null;
		return {
			...bk,
			marked: this.store.isBookmarked(bk.url),
			disabled: this.store.isDisabled(bk.url),
		}
	})
}
